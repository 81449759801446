.modalBody {
  background-color: #352374; /* For browsers that do not support gradients */
  background-image: linear-gradient(to bottom right, #352374, #7b68bf);
}

.containercards {
  display: flex;
  align-items: center;
  text-align: center;
}

.card {
  width: 250px;
  min-height: 200px;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  padding: 35px;

  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  margin-right: 10px;
  backdrop-filter: blur(15px);
}

.number {
  font-size: 32px;
}

.titlePaquete {
  font-size: 22px;
}

.cardFooter {
  font-size: 0.85em;
  color: #446;
}
