.agFormatContainer {
  width: 1142px;
  margin: 0 auto;
}

.agCoursesBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  padding: 50px 0;
}
.agCoursesItem {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);

  margin: 0 15px 30px;

  overflow: hidden;

  border-radius: 28px;
  cursor: pointer;
}
.agCoursesItemLink {
  display: block;
  padding: 30px 20px;
  background-color: #111827;

  overflow: hidden;

  position: relative;
}
.agCoursesItemLink:hover,
.agCoursesItemLink:hover .ag-courses-item_date {
  text-decoration: none;
  color: #fff;
}
.agCoursesItemLink:hover .agCoursesItemBg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.agCoursesItemTitle {
  margin: 0 0 10px;

  overflow: hidden;

  font-size: 20px;
  color: #fff;

  z-index: 2;
  position: relative;
}
.agCoursesItemDateBox {
  margin-top: 10px;
  font-size: 18px;
  color: #fff;

  z-index: 2;
  position: relative;
}
.agCoursesItemDate {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
.agCoursesItemBg {
  height: 128px;
  width: 128px;
  background-color: #362f78;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media only screen and (max-width: 979px) {
  .agCoursesItem {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .agCoursesItemTitle {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }
}
@media only screen and (max-width: 639px) {
  .agCoursesItem {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .agCoursesItemTitle {
    min-height: 72px;
    line-height: 1;

    font-size: 24px;
  }
  .agCoursesItemLink {
    padding: 22px 40px;
  }
  .agCoursesItemDateBox {
    font-size: 16px;
  }
}
