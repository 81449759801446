@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

.maincontainer {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url("../../img/fond2.jpg");
  background-size: 50%;
  font-family: "Jost", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.header {
  font-weight: bold;
  margin: 0;
}

.pDialog {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.span {
  font-size: 12px;
}

.link {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.buttonLogin {
  border-radius: 20px;
  border: 1px solid #362872;
  background-color: #362872;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.buttonLogin:active {
  transform: scale(0.95);
}

.buttonLogin:focus {
  outline: none;
}

.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.formulario {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.inputLogin {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}

.formContainer {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.signInContainer {
  left: 0;
  width: 50%;
  z-index: 2;
}

.trasladeHSignIn {
  transform: translateX(100%);
}

.signUpContainer {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.trasladeHSignUp {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlayContainer {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.trasladeHOverlay {
  transform: translateX(-100%);
}

.overlay {
  background: #0f0344;
  background: -webkit-linear-gradient(to right, #362872, #0f0344);
  background: linear-gradient(to right, #362872, #0f0344);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.trasladeHOver {
  transform: translateX(50%);
}

.overlayPanel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlayLeft {
  transform: translateX(-20%);
}

.trasladeHOverLeft {
  transform: translateX(0);
}

.overlayRight {
  right: 0;
  transform: translateX(0);
}

.trasladeHOverRight {
  transform: translateX(20%);
}
